<button
  class="btn btn-sm action-btn"
  [ngClass]="{ 'disabled': isSubmitting,
               'btn-outline-secondary': !profile.following,
               'btn-secondary': profile.following }"
  (click)="toggleFollowing()">
  <i class="ion-plus-round"></i>
  &nbsp;
  {{ profile.following ? 'Unfollow' : 'Follow' }} {{ profile.username }}
</button>
