<div class="article-meta">
  <a [routerLink]="['/profile', article.author.username]">
    <img [src]="article.author.image" />
  </a>

  <div class="info">
    <a class="author"
      [routerLink]="['/profile', article.author.username]">
      {{ article.author.username }}
    </a>
    <span class="date">
      {{ article.createdAt | date: 'longDate' }}
    </span>
  </div>

  <ng-content></ng-content>
</div>
