<app-article-preview
  *ngFor="let article of results"
  [article]="article">
</app-article-preview>

<div class="app-article-preview"
  [hidden]="!loading">
  Loading articles...
</div>

<div class="app-article-preview"
  [hidden]="loading || results.length">
  No articles are here... yet.
</div>

<nav [hidden]="loading || totalPages.length <= 1">
  <ul class="pagination">

    <li class="page-item"
      [ngClass]="{'active': pageNumber === currentPage}"
      *ngFor="let pageNumber of totalPages"
      (click)="setPageTo(pageNumber)">

      <a class="page-link" >{{ pageNumber }}</a>

    </li>

  </ul>
</nav>
