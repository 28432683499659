<div class="home-page">

  <div class="banner" *appShowAuthed="false">
    <div class="container">
      <h1 class="logo-font">conduit</h1>
      <p>A place to share your <i>Angular</i> knowledge.</p>
    </div>
  </div>

  <div class="container page">
    <div class="row">

      <div class="col-md-9">
        <div class="feed-toggle">
          <ul class="nav nav-pills outline-active">
            <li class="nav-item">
              <a class="nav-link"
                 [ngClass]="{'active': listConfig.type === 'feed'}"
                 (click)="setListTo('feed')">
                 Your Feed
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link"
                 [ngClass]="{'active': listConfig.type === 'all' && !listConfig.filters.tag}"
                 (click)="setListTo('all')">
                 Global Feed
              </a>
            </li>
            <li class="nav-item" [hidden]="!listConfig.filters.tag">
              <a class="nav-link active">
                <i class="ion-pound"></i> {{ listConfig.filters.tag }}
              </a>
            </li>
          </ul>
        </div>

        <app-article-list [limit]="10" [config]="listConfig"></app-article-list>
      </div>

      <div class="col-md-3">
        <div class="sidebar">
          <p>Popular Tags</p>

          <div class="tag-list">
            <a *ngFor="let tag of tags"
               (click)="setListTo('all', {tag: tag})"
               class="tag-default tag-pill">
               {{ tag }}
            </a>
          </div>

          <div [hidden]="tagsLoaded">
            Loading tags...
          </div>

          <div [hidden]="!tagsLoaded || tags.length > 0">
            No tags are here... yet.
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
