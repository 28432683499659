<nav class="navbar navbar-light">
  <div class="container">
    <a class="navbar-brand" routerLink="/">conduit</a>

    <!-- Show this for logged out users -->
    <ul *appShowAuthed="false"
      class="nav navbar-nav pull-xs-right">

      <li class="nav-item">
        <a class="nav-link"
          routerLink="/">
          Home
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link"
          routerLink="/login"
          routerLinkActive="active">
          Sign in
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link"
          routerLink="/register"
          routerLinkActive="active">
          Sign up
        </a>
      </li>

    </ul>

    <!-- Show this for logged in users -->
    <ul *appShowAuthed="true"
      class="nav navbar-nav pull-xs-right">

      <li class="nav-item">
        <a class="nav-link"
          routerLink="/"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }">
          Home
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link"
          routerLink="/editor"
          routerLinkActive="active">
          <i class="ion-compose"></i>&nbsp;New Article
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link"
          routerLink="/settings"
          routerLinkActive="active">
          <i class="ion-gear-a"></i>&nbsp;Settings
        </a>
      </li>

      <li class="nav-item">
        <a class="nav-link"
          [routerLink]="['/profile', currentUser.username]"
          routerLinkActive="active">
          <img [src]="currentUser.image" *ngIf="currentUser.image" class="user-pic" />
          {{ currentUser.username }}
        </a>
      </li>

    </ul>

  </div>
</nav>
